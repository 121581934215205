import "../public/styles/globals.css";
import type { AppProps } from "next/app";
import { MainLayout } from "../components/MainLayout";
import { useRouter } from "next/router";
import AuthProvider from "../components/AuthProvider";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import Head from "next/head";

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const route = router.pathname;
  if (route === "/auth") {
    return <Component {...pageProps} />;
  } else {
    return (
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#EB2F96",
            borderRadius: 2,
          },
        }}
        locale={ruRU}
      >
        <AuthProvider>
          <MainLayout>
            <Head>
              <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#5bbad5"
              />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />
            </Head>

            <Component {...pageProps} />
          </MainLayout>
        </AuthProvider>
      </ConfigProvider>
    );
  }
}

export default App;
