export default class NotFoundError extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly message: string;

  constructor(response: Response, description: string) {
    super(response.statusText);

    this.name = "NotFoundError";
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.message = description + "\n" + "url: " + this.url;
  }
}
