import { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import styles from "./Header.module.scss";
import { Layout } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import logo from "../../public/images/logo.svg";
import { useRouter } from "next/router";
import AuthRequest from "../../api/AuthRequest";
import { useLocalStorage } from "../../libs/hooks/useLocalStorage";
import { USER_ROLE } from "../../typedef";

export const Header: FC = () => {
  const { push } = useRouter();
  const handleLogout = async () => {
    await AuthRequest.logoutRequest();
    push("/auth");
  };
  const [value] = useLocalStorage<USER_ROLE | null>("role", null);

  const { Header } = Layout;
  return (
    <Header className={styles.header}>
      <Link
        href={value === "admin" ? "/content/makeup" : "/partner"}
        className={styles.logoContainer}
      >
        <a className={styles.logo}>
          <Image src={logo} alt="logo" />
          <h1 className={styles.title}>
            {value === "admin"
              ? "Административная панель"
              : "Статистика партнёра"}
          </h1>
        </a>
      </Link>
      <button onClick={handleLogout} className={styles.logout}>
        <LogoutOutlined />
      </button>
    </Header>
  );
};
