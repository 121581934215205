import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import AuthRequest from "../../api/AuthRequest";
import { USER_ROLE } from "../../typedef";
import { useLocalStorage } from "../../libs/hooks/useLocalStorage";

export const AuthContext = createContext<{
  role: USER_ROLE | null;
  setRole: Dispatch<SetStateAction<USER_ROLE | null>>;
}>({
  role: null,
  setRole: () => {},
});

const AuthProvider: FC = ({ children }) => {
  const { push } = useRouter();
  const [value] = useLocalStorage<USER_ROLE | null>("role", null);
  const [role, setRole] = useState<USER_ROLE | null>(value);

  const isUserLoggedIn = async () => {
    try {
      await AuthRequest.checkUserLoggedIn();
    } catch (e) {
      push("/auth");
    }
  };
  useEffect(() => {
    isUserLoggedIn();
  }, []);

  return (
    <AuthContext.Provider value={{ role, setRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
