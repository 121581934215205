import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Layout, Menu } from "antd";
import styles from "./Sider.module.scss";
import { navigationRoutes, partnerRoutes } from "../../libs/routes";
import { useLocalStorage } from "../../libs/hooks/useLocalStorage";
import { USER_ROLE } from "../../typedef";

const { Sider: AntSider } = Layout;

const { SubMenu } = Menu;

export const Sider: FC = () => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const [value] = useLocalStorage<USER_ROLE | null>("role", null);

  const router = useRouter();
  const routes = value === "admin" ? navigationRoutes : partnerRoutes;

  useEffect(() => {
    routes.forEach((route, index) => {
      if (
        "submenu" in route &&
        route.submenu.find((item) => {
          return item.url === router.pathname;
        })
      ) {
        setOpenKeys([index.toString()]);
      }
    });
  }, [router.pathname, routes]);

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };
  return (
    <AntSider width={256} className={styles.sider_height}>
      <Menu
        className={styles.sider}
        mode="inline"
        selectedKeys={[router.pathname]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
      >
        {routes.map((route, index) => {
          return "submenu" in route ? (
            <SubMenu key={index} icon={route.icon} title={route.name}>
              {route.submenu.map((item) => {
                return (
                  <Menu.Item key={item.url}>
                    <Link href={item.url}>
                      <a>{item.name}</a>
                    </Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          ) : (
            <Menu.Item icon={route.icon} key={route.url}>
              <Link href={route.url}>
                <a>{route.name}</a>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </AntSider>
  );
};
