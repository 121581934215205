import { Layout } from "antd";
import { Header } from "../Header";
import { Sider } from "../Sider";
import { FC } from "react";
import { Breadcrumbs } from "../Breadcrumb";

const { Content } = Layout;

export const MainLayout: FC = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Layout>
        <Sider />
        <Content>
          <Breadcrumbs />
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
