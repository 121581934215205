import { Breadcrumb } from "antd";
import { FC } from "react";
import { useRouter } from "next/router";
import styles from "./Breadcrumbs.module.scss";
import { isString } from "../../libs/typeGuards";
import {
  breadCrumbsConfig,
  breadCrumbsFirstLevelPrefix,
  breadCrumbsPostfixExactMatch,
} from "../../libs/breadCrumbsConfig";

export const Breadcrumbs: FC = () => {
  const { pathname, query } = useRouter();
  const linkPath = pathname.split("/").filter((s) => {
    return s;
  });

  const id = isString(query.id) ? query.id : "";

  const pathArray = linkPath.map((path, i) => {
    if (i === 0 && Object.keys(breadCrumbsFirstLevelPrefix).includes(path)) {
      return [
        {
          breadcrumb: path,
          href: "#",
          exactMatch: false,
        },
        {
          breadcrumb: path,
          href: "/" + linkPath.slice(0, i + 1).join("/"),
          exactMatch: false,
        },
      ];
    }

    return {
      breadcrumb: path,
      href: "/" + linkPath.slice(0, i + 1).join("/"),
      exactMatch: false,
    };
  });

  const flatPathArray = pathArray.flat();

  const exactPathMatch = breadCrumbsPostfixExactMatch[pathname];

  if (exactPathMatch) {
    flatPathArray.push({
      breadcrumb: exactPathMatch,
      href: "#",
      exactMatch: true,
    });
  }

  let breadCrumbs: Record<string, string> = {
    ...breadCrumbsConfig,
    "[id]": id,
  };

  return (
    <Breadcrumb
      className={styles.breadcrumb}
      items={flatPathArray.map((item, index) => {
        if (index === 0 && item.href === "#") {
          return {
            title: breadCrumbsFirstLevelPrefix[item.breadcrumb],
          };
        }
        if (index === flatPathArray.length - 1 && item.exactMatch) {
          return {
            title: item.breadcrumb,
          };
        }
        if (index === flatPathArray.length - 1) {
          return {
            title: breadCrumbs[item.breadcrumb],
          };
        }
        return {
          href: item.href,
          title: breadCrumbs[item.breadcrumb],
        };
      })}
    ></Breadcrumb>
  );
};
