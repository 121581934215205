export function isString(value: unknown): value is string {
  return typeof value === "string";
}

/**
 * Тайгард для Возвращаемого объекта от FUS
 *
 * @return boolean
 *
 * @static
 * @param value - Обьект ответа от FUS
 */
export function isFusResponse(
  value: unknown,
): value is Record<string, unknown> {
  return (
    typeof value === "object" &&
    value !== null &&
    "file" in value &&
    "uploadedFileData" in value
  );
}
/**
 * Тайгард для обьекта File из возвращаемого FUS
 *
 * @return boolean
 *
 * @static
 * @param value - Обьект File от FUS
 */
export function isFusRespFile(
  value: unknown,
): value is Record<string, unknown> {
  return typeof value === "object" && value !== null && "url" in value;
}

export function queryBooleanString(query: string | string[]) {
  if (query === "1" || query === "0") {
    return query;
  }
  return "0";
}
