import MiddlewareError from "./errors/MiddlewareError";
import NotFoundError from "./errors/NotFoundError";

interface IRequestToMiddlewareParams<RequestData> {
  path: string;
  method: string;
  data?: RequestData;
  headers?: Record<string, string>;
  signal?: AbortSignal;
}

/**
 *
 * @param path - api route path without /api/ prefix
 * @param method - HTTP method type
 * @param data - request params
 * @param headers - HTTP headers
 * @param signal - AbortController signal
 *
 * Для запросов с сервера необходимо передать абсолютный url (originPath + path)
 * Для запросов с клиента необходимо передать относительный url
 */

export default async function requestToMiddleware<
  RequestResponse,
  RequestData extends Record<string, unknown> | undefined = undefined,
>({
  path,
  method,
  data,
  headers,
  signal,
}: IRequestToMiddlewareParams<RequestData>): Promise<RequestResponse> {
  const fetchParams: RequestInit = {
    method,
    ...(signal && { signal }),
    ...(headers && { headers }),
  };

  let url = "/api/" + path;

  if (method === "GET" && data) {
    // Здесь мы точно знаем, что URLSearchParams хорошо обрабатывает
    // в том числе и массивы ([1, 2, 3] === 1,2,3) и остальные типы, за исключением обьектов.
    // Поэтмоу ставим as, а TypeScript почему-то загоняет в рамки, что конструктор URLSearchParams
    // принимает только [key: string]: string.
    const query = new URLSearchParams(
      data as Record<string, string>,
    ).toString();
    url = `${url}?${query}`;
  } else if (data) {
    if (data instanceof FormData) {
      fetchParams.body = data;
    } else {
      fetchParams.body = JSON.stringify(data);
    }
  }

  const resp = await fetch(url, fetchParams);

  if (resp.status === 404) {
    throw new NotFoundError(resp, "Probably you call nonexistent api route");
  }

  const body = resp.status !== 204 && (await resp.json());

  if (typeof body === "object" && "failedToRefresh" in body) {
    window.location.href = "/auth";
    throw new MiddlewareError(resp, body);
  }

  if (!resp.ok) {
    throw new MiddlewareError(resp, body);
  }

  return body;
}
