export const breadCrumbsConfig: Record<string, string> = {
  content: "Контент",
  categories: "Категории",
  makeup: "Макияж",
  promocodes: "Список промокодов",
  analytics: "Аналитика",
  users: "Список пользователей",
  admins: "Список администраторов",
  lessons: "Уроки",
  addCategory: "Новая категория",
  addMakeup: "Создание макияжа",
  addAdmin: "Добавление администратора",
  addPartner: "Добавление партнера",
  add: "Создание урока",
  partners: "Партнеры",
  products: "Продукты",
  createProduct: "Создание продукта",
};

// В макетах у разделов (по крайней мере новых) первого уровня есть не кликабельный префикс;
export const breadCrumbsFirstLevelPrefix: Record<string, string> = {
  users: "Пользователи",
  admins: "Пользователи",
  promocodes: "Промокоды",
};

// Например, на странице создания администратора мы не можем добавить в конце крошу "Создание администратора"
// С этим объектом у нас появляется такая возможность
export const breadCrumbsPostfixExactMatch: Record<string, string> = {
  "/admins/createAdmin": "Создание администратора",
};
