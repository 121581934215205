import requestToMiddleware from "../requestToMiddleware";
import { SignInRequest } from "../../pages/api/authorization/signIn";
import { USER_ROLE } from "../../typedef";
import { AccessToken } from "@generated-admin/models/AccessToken";

export default class AuthRequest {
  public static async loginRequest(
    data: SignInRequest,
    signal?: AbortSignal,
  ): Promise<{ accessToken: AccessToken; profile: USER_ROLE }> {
    return await requestToMiddleware<
      { accessToken: AccessToken; profile: USER_ROLE },
      SignInRequest
    >({
      path: "authorization/signIn",
      method: "POST",
      data,
      signal,
    });
  }

  public static async logoutRequest(
    signal?: AbortSignal,
  ): Promise<AccessToken> {
    return await requestToMiddleware<AccessToken>({
      path: "authorization/signOut",
      method: "POST",
      signal,
    });
  }

  public static async checkUserLoggedIn(): Promise<string> {
    return await requestToMiddleware<string>({
      path: "authorization/checkUserSignIn",
      method: "GET",
    });
  }
}
