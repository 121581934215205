import {
  AppstoreFilled,
  BarcodeOutlined,
  ShoppingFilled,
  UserOutlined,
} from "@ant-design/icons";
import { NavigationRoute } from "./routes.typedef";

export const navigationRoutes: NavigationRoute[] = [
  {
    name: "Контент",
    submenu: [
      { url: "/content/makeup", name: "Макияжи" },
      { url: "/content/lessons", name: "Уроки" },
      { url: "/content/products", name: "Продукты" },
      { url: "/content/categories", name: "Категории" },
    ],
    icon: <AppstoreFilled />,
  },
  {
    name: "Пользователи",
    submenu: [
      { url: "/admins", name: "Список администраторов" },
      { url: "/users", name: "Список пользователей" },
    ],
    icon: <UserOutlined />,
  },
  {
    name: "Промокоды",
    url: "/promocodes",
    icon: <BarcodeOutlined />,
  },
  {
    name: "Партнёрская программа",
    submenu: [
      { url: "/partners/analytics", name: "Аналитика" },
      { url: "/partners", name: "Список партнёров" },
    ],
    icon: <ShoppingFilled />,
  },
];

export const partnerRoutes = [
  {
    name: "Аналитика",
    url: "/partner",
    icon: <UserOutlined />,
  },
];
